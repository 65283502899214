var isRTL = ($('html').hasClass('is-ar') == "rtl") ? true : false;

var isIE = (!!window.MSInputMethodContext && !!document.documentMode) || (document.documentMode || /Edge/.test(navigator.userAgent));

if(isIE){ $('body').addClass('is--ie'); }

var winWidth = $(window).width(),
	winHeight = $(window).height();

setMobileHeight();

$(function () {
	winDimensions();
	setMobileHeight();
});

//On Window Load
$(window).on('load', function () {
	setTimeout(function () {
		setMobileHeight();
		pageLoaded();
		gridBoxHeight();
		jsImg();
	}, 500);
});

//On Window Resize
$(window).on('resize orientationchange', function () {
	if(winWidth != $(window).width()){
		gridBoxHeight();
		winDimensions();
		dontAllowPortraitMode();
		// layoutHeight();
		setMobileHeight();
	}
});

//On Scroll
/*$(window).on('scroll', function () {
});*/

//Escape Functionality
/*$(document).keyup(function(e) {
    if (e.keyCode == 27) {
    }
});*/


function winDimensions() {
	winWidth = $(window).width();
	winHeight = $(window).height();
}


//Define Functions
/*function getScrollBarWidth() {
	var inner = document.createElement('p');
	inner.style.width = "100%";
	inner.style.height = "200px";
	var outer = document.createElement('div');
	outer.style.position = "absolute";
	outer.style.top = "0px";
	outer.style.left = "0px";
	outer.style.visibility = "hidden";
	outer.style.width = "200px";
	outer.style.height = "150px";
	outer.style.overflow = "hidden";
	outer.appendChild(inner);
	document.body.appendChild(outer);
	var w1 = inner.offsetWidth;
	outer.style.overflow = 'scroll';
	var w2 = inner.offsetWidth;
	if (w1 == w2) {
		w2 = outer.clientWidth;
	}
	document.body.removeChild(outer);
	return (w1 - w2);
}*/
/*function ChangeToSvg() {
	jQuery('img.tosvg').each(function () {
	    var $img = jQuery(this);
	    var imgID = $img.attr('id');
	    var imgClass = $img.attr('class');
	    var imgURL = $img.attr('src');
	    jQuery.get(imgURL, function (data) {
	      var $svg = jQuery(data).find('svg');
	      if (typeof imgID !== 'undefined') {
	        $svg = $svg.attr('id', imgID);
	      }
	      if (typeof imgClass !== 'undefined') {
	        $svg = $svg.attr('class', imgClass + ' insvg');
	      }
	      $svg = $svg.removeAttr('xmlns:a');
	      if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
	        $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
	      }
	      $img.replaceWith($svg);
	    }, 'xml');
	});
}*/




var sliderIntervals,
sliderIntervalCount;
// var timelineItems = $('.screen').length - 1;
var timelineItems = 3;
// var startValue = (isRTL) ? timelineItems : 1;
var slider = $(".js-dragable-slider").slider({
	step: 0.0001,
	range: false,
	min: 1,
	max: timelineItems,
	value: 2,
	// animate: "fast",
	//animate:"1000",
	//orientation: "horizontal",
	slide: function( event, ui ) {
		setLineWidth(ui.handle, ui.value);
    },
	change: function( event, ui ) {
		setLineWidth(ui.handle, ui.value);

		if(ui.value == 1){
			if($('.page--active').attr('data-id').split('-')[1] == "ar"){
				switchPage('screenQAH-ar', 'left', 'right');
			}else{
				switchPage('screenQAH', 'left', 'right');
			}
			$('.section-dragable').animate({'opacity': 0}, 'fast', function(){ $('.section-dragable').height('0'); $('.section-dragable').css({'overflow': 'hidden'}); });
			setTimeout(function(){
				initGallery();
			}, 900);
		}else if(ui.value == 3){
			if($('.page--active').attr('data-id').split('-')[1] == "ar"){
				switchPage('screenCF-ar', 'right', 'left');
			}else{
				switchPage('screenCF', 'right', 'left');
			}
			$('.section-dragable').animate({'opacity': 0}, 'fast', function(){ $('.section-dragable').height('0'); $('.section-dragable').css({'overflow': 'hidden'}); });
			setTimeout(function(){
				initGallery();
			}, 900);
		}
    },
	stop: function( event, ui ) {
		var sliderValue1 = Math.round(ui.value);
		$(".js-dragable-slider").slider('value',sliderValue1);
		sliderIntervalCount = 1;
		clearTimeout(sliderIntervals);
		sliderIntervals = setInterval(function () {
			sliderIntervalCount++;
			if(sliderIntervalCount > 10){
				clearTimeout(sliderIntervals);
			}
		}, 100);

		setLineWidth(ui.handle, ui.value);
	},
	create: function ( event, ui ) {
		var appendHtml = '<div class="checkpoints">';
		for (var i = timelineItems - 1; i >= 0; i--) {
			appendHtml += '<i class="point"></i>';
		}
		appendHtml += '</div>';
		$(".js-dragable-slider").append(appendHtml);
	},
});

function setLineWidth(handle, uiValue) {
	var leftLine = $(handle).parent().find('.line--left');
	var rightLine = $(handle).parent().find('.line--right');
	var handleWidth = $(handle).width();
	var sliderValFloat = uiValue;
	var handleBarWidth = $(handle).parent().width();
	var perCom = (sliderValFloat-1)/2*100;
	// 50 / 100 * 285
	var leftLineWidth = perCom/100*handleBarWidth - ((handleWidth/2) + 6);
	var rightLineWidth = Math.abs(perCom-100)/100*handleBarWidth - ((handleWidth/2) + 6);

	if(winWidth < 767){
		leftLineWidth = leftLineWidth+2;
		rightLineWidth = rightLineWidth+2;
	}

	leftLine.width(leftLineWidth);
	rightLine.width(rightLineWidth);
}

function dontAllowPortraitMode() {
 if(isMobile && (winWidth > winHeight)){
    	$('#portrait-warnning').css('display', 'flex');
    }else{
    	$('#portrait-warnning').css('display', 'none');
    }
}

// var _fromId, _toId;
var switchingPage = false;

var length = $('.page-collection > .page-base').length,
    current = 1,
    next = 1,
    outClass, inClass;

function show(targetID) {
	targetID = '[data-id="'+ targetID + '"]';

	if(isIE){
		outClass = 'fadeOut';
		inClass = 'fadeIn';
		$('.page-collection > .page-base.page--active').addClass(outClass);
    	$('.page-collection > .page-base.page--active').addClass('remove-active-state');

		$(targetID).addClass(inClass);
		$(targetID).addClass('page--active');


	    setTimeout(function() {
	    	$('.page-collection > .page-base.remove-active-state').addClass('half-way-through');
	    }, 33);

		setTimeout(function() {
			$('.page-collection > .page-base.remove-active-state').removeClass('page--active');

			setTimeout(function() {
				$('.page-collection > .page-base.remove-active-state').removeClass(outClass);
        		$('.page-collection > .page-base.remove-active-state').removeClass('remove-active-state');
				$('.page-collection > .page-base.page--active').removeClass(inClass);
		        current = next;
		        switchingPage = false;
		        if($(targetID).find('.slick-active video').get(0)){
		        	$(targetID).find('.slick-active video')[0].play();
		        }
			}, 200);
		}, 100);
		return;
	}
    
    $('.page-collection > .page-base.page--active').addClass(outClass);
    $('.page-collection > .page-base.page--active').addClass('remove-active-state');

    $(targetID).addClass(inClass);
    $(targetID).addClass('page--active');

    setTimeout(function() {
    	$('.page-collection > .page-base.remove-active-state').addClass('half-way-through');
    }, 250);
    setTimeout(function() {
        $('.page-collection > .page-base.remove-active-state').removeClass('page--active');
    }, 600);

    setTimeout(function() {
        $('.page-collection > .page-base.remove-active-state').removeClass(outClass);
        $('.page-collection > .page-base.remove-active-state').removeClass('half-way-through');
        $('.page-collection > .page-base.remove-active-state').removeClass('remove-active-state');
        $('.page-collection > .page-base.page--active').removeClass(inClass);
        current = next;
        switchingPage = false;
        if($(targetID).find('.slick-active video').get(0)){
        	$(targetID).find('.slick-active video')[0].play();
    	}
    }, 900);
}

var switchPageToId;
function switchPage(toId, direcFrom, direcTo) {
	// console.log(toId, direcFrom, direcTo);
    if (direcFrom == 'top') {
        next = current > 1 ? current - 1 : length;
        outClass = 'rotateCubeBottomOut top';
        inClass = 'rotateCubeBottomIn';
    } else if (direcFrom == 'bot') {
        next = current < length ? current + 1 : 1;
        outClass = 'rotateCubeTopOut top';
        inClass = 'rotateCubeTopIn';
    } else if (direcFrom == 'left') {
        next = current < length ? current + 1 : 1;
        outClass = 'rotateCubeRightOut left';
        inClass = 'rotateCubeRightIn';
    } else if (direcFrom == 'right') {
        next = current < length ? current + 1 : 1;
        outClass = 'rotateCubeLeftOut left';
        inClass = 'rotateCubeLeftIn';
    }

	if(switchingPage == false){
		switchingPage = true;
		switchPageToId = toId;
		setTimeout(function(){
    		show(switchPageToId);
		}, 50);
	}
}

// switchPage('screenQAH');

$('[data-topage]').click(function (e) {
	e.preventDefault();
	var toPage = $(this).attr('data-topage');
	var pageFrom = $(this).attr('data-animfrom');
	var pageTo;
	
	if(toPage == 'screenCF' || toPage == 'screenCF-ar' || toPage == 'screenQAH' || toPage == 'screenQAH-ar'){
		setTimeout(function(){
			initGallery();
		}, 900);
	}else{
		destroyGallery();
	}

	switch(pageFrom) {
	    case 'top':
	        pageTo = 'bot';
	        break;
	    case 'bot':
	        pageTo = 'top';
	        break;
	    case 'left':
	        pageTo = 'right';
	        break;
	    case 'right':
	        pageTo = 'left';
	        break;
	    default:
	    	pageTo = 'left';
	}

	switchPage(toPage, pageFrom, pageTo);

	if(toPage == 'home' || toPage == 'home-ar'){
		$(".js-dragable-slider").slider('value', 2);
		$('.section-dragable').height('auto');
		$('.section-dragable').css({'overflow': 'visible'});
		setTimeout(function () {
			$('.section-dragable').animate({'opacity': 1, 'margin-bottom': 0}, 'fast');
		}, 1000);
	}else{
		$('.section-dragable').animate({'opacity': 0, 'margin-bottom': '-20px'}, 'fast', function(){ $('.section-dragable').height('0'); $('.section-dragable').css({'overflow': 'hidden'}); });
	}
});


function setMobileHeight() {
	if(winWidth < 768){
		$('.page-collection').height(winHeight);
	}
}

/*function layoutHeight() {
	if(winWidth < 768){
		var totalHeight = 0;
		$('#home .section-content .content .overflow-div > *').each(function () {
			totalHeight = totalHeight + $(this).height();
		});
		var negHeight = winHeight-totalHeight;
		if(negHeight < 223){
			$('body').addClass('shrink-height-1');
			if(negHeight < 124){
				$('body').addClass('shrink-height-2');
				if(negHeight < 110){
					$('body').addClass('shrink-height-3');
				}
			}
		}
	}
}*/



/*function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function validateForm() {
  var $result = $("#result");
  var email = $("#email").val();
  $result.text("");

  if (validateEmail(email)) {
    $result.text(email + " is valid :)");
    $result.css("color", "green");
  } else {
    $result.text(email + " is not valid :(");
    $result.css("color", "red");
  }
  return false;
}

$("#validate").bind("click", validate);*/

var initiGallerFirstTime = true;
function initGallery() {
	$('.js-gallery-carousel').each(function () {
		$(this).slick({
			arrows: false,
			fade: true,
			// rtl: isRTL,
		});

		$(this).on('beforeChange', function(event, slick, currentSlide, nextSlide){
			// console.log(slick.$list, currentSlide, nextSlide);
			if(slick.$list.parent().find('[data-slick-index="'+nextSlide+'"] video').get(0)){
				setTimeout(function(){
					slick.$list.parent().find('[data-slick-index="'+nextSlide+'"] video')[0].play();
				}, 100);
				
			}

			if(slick.$list.parent().find('video').get(0)){
				slick.$list.parent().find('video')[0].pause();
			}

			var totalLength = slick.$list.parent().find('[data-slick-index]').length - 1;

			if(nextSlide == 0){
				var currentImg = slick.$list.parent().find('[data-slick-index="'+totalLength+'"]').find('img').attr('src');
			}else{
				var currentImg = slick.$list.parent().find('[data-slick-index="'+nextSlide+'"]').prev().find('img').attr('src');
			}

			if(nextSlide == totalLength){
				var nextImg = slick.$list.parent().find('[data-slick-index="'+ 0 +'"]').find('img').attr('src');
			}else{
				var nextImg = slick.$list.parent().find('[data-slick-index="'+nextSlide+'"]').next().find('img').attr('src');
			}

			slick.$list.parent().parent().find('.js-carousel-prev img').attr('src', currentImg);
			slick.$list.parent().parent().find('.js-carousel-next img').attr('src', nextImg);
		});
	});
	bindPopupEve();

	if(initiGallerFirstTime){
		$('.js-carousel-next').click(function (e) {
			e.preventDefault();
			$(this).parent().find('.js-gallery-carousel').slick('slickNext');
		});

		$('.js-carousel-prev').click(function (e) {
			e.preventDefault();
			$(this).parent().find('.js-gallery-carousel').slick('slickPrev');
		});
	}
}

function destroyGallery(){
	$('.js-gallery-carousel').each(function () {
		if($(this).hasClass('slick-initialized')){
			var currentImgSrc = $(this).find('.slick-active img').attr('src');
			$(this).slick('unslick');
			
			var lastChild = $(this).find('.item:last-child');
			$(this).find('img[src="'+currentImgSrc+'"]').parent().prevAll().each(function(){
				$(this).insertAfter(lastChild);
			});
		}
	});
}

function pageLoaded() {
	setTimeout(function () {
		$('body').addClass('loading-done');
		setTimeout(function () {
			$('.c-loader').fadeOut('fast', function () {
				$('.c-loader').hide();
			});
		}, 700);
	}, 2500);
}




/*var ENFormhHtml = $('.screen-menu:not(.is-ar) #mc_embed_signup')[0].outerHTML;
var ARFormhHtml = $('.screen-menu.is-ar #mc_embed_signup')[0].outerHTML;*/

/*function jsSubmit(self) {
	// body...

  if($(self).closest('.page-base').hasClass('is-ar')){

	  var mailchimpEmail = $(self).closest('form').find('#mce-EMAIL');
	  var mailchimpName = $('#mce-FNAMEAR');
	  var mailchimpSwitchCF = $('#gdpr_11833');
	  var mailchimpSwitchAH = $('#gdpr_11837');
	  var subscribeForm = $(self).closest('form');
	  var formStatus = 0;

	  mailchimpEmail.parent().removeClass('error');
	  mailchimpName.parent().removeClass('error');

	  if (! (mailchimpSwitchCF.is(":checked") || mailchimpSwitchAH.is(":checked")) ) {
	      formStatus = 1;
	      mailchimpSwitchCF.parent().addClass('error');
	      mailchimpSwitchAH.parent().addClass('error');
	  }else{
	      mailchimpSwitchCF.parent().removeClass('error');
	      mailchimpSwitchAH.parent().removeClass('error');
	  }

	  if (mailchimpEmail.val() == '' || validateEmail(mailchimpEmail.val()) == 0) {
	      formStatus = 1;
	      mailchimpEmail.addClass('error');
	  }else{
		  mailchimpEmail.removeClass('error');
	  }

	  if(mailchimpName.val() == ''  ){
	      formStatus = 1;
	      mailchimpName.addClass('error');
	      // alert("Error");
	  }else{
	  	mailchimpName.removeClass('error');
	  }
	  
	  if(formStatus == 0){
	  	console.log('it worked!!');
	  	grecaptcha.execute();
	    // subscribeForm.submit();
	  }

  }else{

	  var mailchimpEmail = $('#mce-EMAIL');
	  var mailchimpName = $('#mce-FNAME');
	  var mailchimpSwitchCF = $('#gdpr_11765');
	  var mailchimpSwitchAH = $('#gdpr_11761');
	  var subscribeForm = $('#mc-embedded-subscribe-form');
	  var formStatus = 0;

	  mailchimpEmail.parent().removeClass('error');
	  mailchimpName.parent().removeClass('error');

	  if (! (mailchimpSwitchCF.is(":checked") || mailchimpSwitchAH.is(":checked")) ) {
	      formStatus = 1;
	      mailchimpSwitchCF.parent().addClass('error');
	      mailchimpSwitchAH.parent().addClass('error');
	  }else{
	      mailchimpSwitchCF.parent().removeClass('error');
	      mailchimpSwitchAH.parent().removeClass('error');
	  }

	  if (mailchimpEmail.val() == '' || validateEmail(mailchimpEmail.val()) == 0) {
	      formStatus = 1;
	      mailchimpEmail.addClass('error');
	  }else{
		  mailchimpEmail.removeClass('error');
	  }

	  if(mailchimpName.val() == ''  ){
	      formStatus = 1;
	      mailchimpName.addClass('error');
	      // alert("Error");
	  }else{
	  	mailchimpName.removeClass('error');
	  }
	  
	  if(formStatus == 0){
	  	console.log('it worked EN!!');
	    subscribeForm.submit();
	    
	  }
  }
}*/

// $('.js-submit').click(function (e) {
//   e.preventDefault();
//   jsSubmit(this);
// });
// $('.js-submit').click(function (e) {
//   e.preventDefault();
//   jsSubmit(this);
// });


function validateEmail(sEmail) {
  var filter = /^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-z0-9]{2,4}$/;
  if (filter.test(sEmail)) {
    return true;
  }else {
    return false;
  }
}

function gridBoxHeight(){
	var maxHeight = 0;
	$(".boxes-container .content-box").css('height', '100%');
	$(".boxes-container .content-box").each(function() {
		if ($(this).height() > maxHeight) {
			maxHeight = $(this).height();
		}
	}).height(maxHeight);
}

function jsImg() {
	$('.js-img-tag').each(function(){
		var imgUrl = $(this).attr('data-src');
		var imgUrlMob = $(this).attr('data-mob');

		if(winWidth < 768){
			imgUrl = imgUrlMob;
		}

		$(this).replaceWith('<img src="'+imgUrl+'" alt="Img" />');
	});
}
























$(window).on('load', function () {
	setTimeout(function () {
		// addVideoPlugin();
	}, 200);
});

$(document).ready(function () {

	// Popup Close
	$('.js-close-popup').click(function (e) {
		e.preventDefault();
		closePopup();
	});
});

// Close Popup On Escape Key
$(document).keyup(function(e) {
     if (e.keyCode == 27) { 
        closePopup();
    }
});


function openPopup(target) {
	$('html').addClass('popup-is-active');
	$(target).show();
	$(target).closest('.c-popup').show();
	setTimeout(function(){
		$(target).addClass('active');
		$(target).closest('.c-popup').addClass('popup--open');
		// console.log($(target).find('.plyr'));
		if($(target).find('.plyr').length){
			var videoInstance = $(target).find('.plyr').attr('data-video-instance');
			console.log(videoInstance);
			players[videoInstance].play();
		}else if($(target).find('video').length){
			$(target).find('video')[0].play();
		}
	}, 10);


	$('html').addClass('popup-is-active');
	$(target).show();
	$(target).closest('.c-popup').show();
	setTimeout(function(){
		$(target).addClass('active');
		$(target).closest('.c-popup').addClass('popup--open');

		// Play Video
		if(winWidth > 1024 && $(target).find('.plyr').length){
			var videoInstance = $(target).find('.js-video').attr('data-video-instance');
			if(players[videoInstance]){
				players[videoInstance].play();
			}
		}else if($(target).find('.js-video').length){
			$('.popup.active iframe')[0].contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
		}

	}, 10);
}

function closePopup() {
	if($('.c-popup .active').length){
		// Pause Video In Popup
		if(winWidth > 1024 && $('.c-popup .active .plyr').length){
			var videoInstance = $('.c-popup .active .plyr').attr('data-video-instance');
			if(players[videoInstance]){
				players[videoInstance].pause();
			}
		}else if($('.c-popup .active .js-video').length){
			$('.popup.active iframe')[0].contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
		}else if($('.c-popup .active video').length){
			$('.c-popup .active video')[0].pause();
		}

		// Close Popup
		$('.c-popup .active').removeClass('active');
		$('.c-popup').removeClass('popup--open');
		setTimeout(function () {
			$('.c-popup .popup').hide();
			$('.c-popup').hide();
			$('html').removeClass('popup-is-active');
		}, 310);
	}
}

function addVideoPlugin() {
	if(winWidth > 1024 && $('.js-video').get(0)){
		var plyrScriptElement = document.createElement("script");
		if($('html').hasClass('is-ar')){
			plyrScriptElement.setAttribute('src', '../assets/js/plyr.min.js');
		}else{
			plyrScriptElement.setAttribute('src', 'assets/js/plyr.min.js');
		}

		plyrScriptElement.setAttribute('async', 'true');
		document.body.appendChild(plyrScriptElement);
	}else{
		jsVideoDirect();
	}
}

var players = [];
function jsVideo() {
	// Custom player
	if($('.js-video').length){
		$('.js-video').each(function(i) {
			var thisParent = $(this).parent();
			players[i] = new Plyr(this, {
				playsinline: true,
			});
			thisParent.find('.plyr').attr('data-video-instance', i);
		});
	}
}

function jsVideoDirect() {
	if($('.js-video').length){
		$('.js-video').each(function(i) {
			$(this).attr('data-video-instance', i);
			var videoId = $(this).attr('data-plyr-embed-id');
			$(this).html('<iframe width="100%" height="100%" src="https://www.youtube.com/embed/'+videoId+'?rel=0&playsinline=1&enablejsapi=1;rel=0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>');
		});
	}
}

function bindPopupEve() {
	// Popup Open
	$('.js-popup-link').click(function (e) {
		e.preventDefault();
		var target = $(this).attr('data-video');
		openPopup(target);
	});
}